import { Text, View } from '@react-pdf/renderer'
import _ from 'lodash'
import React from 'react'
import { Trans } from 'react-i18next'

import styles from 'app/features/export/components/pdfViewer/category/styles'
import Form from 'app/features/export/components/pdfViewer/form'
import IApp from 'types/app/IApp'
import ICategory from 'types/category/ICategory'
import IForm from 'types/form/IForm'

interface IPdfCategoryProps {
  category: ICategory
  app: IApp
  projectQuestionnaireId: string
  homeUrl: string
  projectId: string
}

/**
 * Pdf Category component
 * Shows brief category in the Pdf file
 * @param {object} props
 * @param {ICategory} props.category
 * @param {IApp} props.app
 * @param {string} props.projectQuestionnaireId
 * @param {string} props.homeUrl
 * @param {string} props.projectId
 */
const PdfCategory: React.FC<IPdfCategoryProps> = ({
  category,
  app,
  projectQuestionnaireId,
  homeUrl,
  projectId,
}: IPdfCategoryProps): React.ReactElement => {
  return (
    <View>
      <View style={styles.categoryContainer}>
        <Text style={styles.categoryHeader}>{category.name}</Text>
      </View>
      {_.isEqual(_.first(app.categories)?.id, category.id) && (
        <View>
          <Text style={styles.fieldHeader}>
            <Trans>form.brief_id_number</Trans>
          </Text>
          <Text style={styles.text}>{projectQuestionnaireId}</Text>
        </View>
      )}
      <View style={styles.formsContainer}>
        {category.forms.map((form: IForm) => (
          <Form key={form.id} form={form} homeUrl={homeUrl} projectId={projectId} />
        ))}
      </View>
    </View>
  )
}

export default PdfCategory
