import { useOs } from '@wpp-open/react'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { Table, WppDatepicker, WppGrid, WppIconChevron, WppTypography } from 'app/components/common'
import { ComponentError } from 'app/components/common/errorComponents'
import Header from 'app/components/toolbar'
import { SideModalFilter } from 'app/features/changeLog/components'
import { ChangeLogService, FilterService } from 'app/features/changeLog/services'
import { useMount } from 'hooks'
import styles from 'pages/changeLog/ChangeLog.module.scss'
import { AppDispatch, RootState } from 'store'
import IAppContextState from 'store/interfaces/IAppContextState'
import IChangeLogState from 'store/interfaces/IChangeLogState'
import IProjectBriefState from 'store/interfaces/IProjectBriefState'
import IQuestionnaireState from 'store/interfaces/IQuestionnaireState'
import { resetState, setFilterDateRange } from 'store/reducers/changeLogSlice'
import IChangeLogFilter from 'types/changeLog/IChangeLogFilter'
import { DatePickerEventDetail, WppDatepickerCustomEvent } from 'types/common/utils'
import { ORDER_BY } from 'types/table/enum'

/**
 * Change Log Component
 */
const ChangeLog: React.FC = (): React.ReactElement => {
  const dispatch = useDispatch<AppDispatch>()
  const { osApi } = useOs()
  const { t } = useTranslation()
  const { filterSelectedData, tableData, sideModalData, error } = useSelector<RootState, IChangeLogState>(
    (state: RootState) => state.changeLogState,
  )
  const projectBriefState = useSelector<RootState, IProjectBriefState>((state: RootState) => state.projectBriefState)
  const questionnaireState = useSelector<RootState, IQuestionnaireState>((state: RootState) => state.questionnaireState)
  const appContext = useSelector<RootState, IAppContextState>((state: RootState) => state.appContext)

  const projectQuestionnaireId: string = _.toString(projectBriefState.questionnaire.projectQuestionnaireId)

  const handleFilterChange = (filter: IChangeLogFilter, orderBy: ORDER_BY): void => {
    ChangeLogService.handleFilterChange({
      accessToken: osApi.getAccessToken(),
      app: questionnaireState.app!,
      page: 1,
      orderBy,
      projectQuestionnaireId,
      tableData,
      projectId: appContext.projectId,
      homeUrl: appContext.homeUrl,
      t,
      tenantId: appContext.tenantId,
      dispatch,
      selectedFilterData: filter,
      filterModalData: sideModalData,
    })
  }

  useMount(() => {
    ChangeLogService.getInitData({
      accessToken: osApi.getAccessToken(),
      app: questionnaireState.app!,
      page: 1,
      orderBy: ORDER_BY.DESC,
      projectQuestionnaireId,
      homeUrl: appContext.homeUrl,
      tableData,
      t,
      tenantId: appContext.tenantId,
      projectId: appContext.projectId,
      dispatch,
      selectedFilterData: filterSelectedData,
      filterModalData: sideModalData,
    })
  })

  useEffect(() => {
    // Reset state when component is unmounted
    return () => {
      dispatch(resetState())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Header>
        <div className={styles.headerItem}>
          <Link className={styles.headerNav} to="/">
            <WppIconChevron direction="left" />
            <WppTypography tag="span" type="s-body">
              {questionnaireState.app?.appName}
            </WppTypography>
          </Link>
          <WppTypography tag="h3" type="2xl-heading">
            <Trans>change_log.title</Trans>
          </WppTypography>
        </div>
      </Header>
      <WppGrid container rowSpacing={2}>
        <WppGrid item all={24}>
          <WppGrid className={styles.container} fullWidth container>
            <WppGrid item all={24} className={styles.filterContainer}>
              <WppDatepicker
                size="s"
                range
                locale={{
                  dateFormat: 'MM/dd/yyyy',
                }}
                className={styles.dateRangePicker}
                maxDate={moment().format('MM/DD/YYYY')}
                value={filterSelectedData.dateRange}
                onWppChange={(event: WppDatepickerCustomEvent<DatePickerEventDetail>) => {
                  const dateRange: any = event.detail.formattedDate
                  if (_.isArray(dateRange)) {
                    dispatch(setFilterDateRange(dateRange))
                    handleFilterChange(
                      {
                        ...filterSelectedData,
                        dateRange,
                      },
                      tableData.orderBy,
                    )
                  }
                }}
                onWppDateClear={() => {
                  const dateRange = FilterService.getFilterDefaultDateRange()
                  dispatch(setFilterDateRange(dateRange))
                }}
              />
              <SideModalFilter handleFilterChange={handleFilterChange} />
            </WppGrid>
            <WppGrid item all={24} className={styles.tableContainer}>
              {error ? (
                <ComponentError title={t('table.error.title')} description={t('table.error.description')} />
              ) : (
                <Table
                  table={tableData}
                  onColumnOrderChange={(orderBy: ORDER_BY): void => {
                    handleFilterChange(filterSelectedData, orderBy)
                  }}
                  customStyle={{
                    height: '68vh',
                  }}
                  infiniteLoading
                  loadMore={(): void => {
                    if (questionnaireState.app) {
                      ChangeLogService.loadMoreData({
                        accessToken: osApi.getAccessToken(),
                        app: questionnaireState.app,
                        orderBy: tableData.orderBy,
                        projectQuestionnaireId,
                        homeUrl: appContext.homeUrl,
                        tableData,
                        tenantId: appContext.tenantId,
                        projectId: appContext.projectId,
                        dispatch,
                        selectedFilterData: filterSelectedData,
                        page: tableData.paginator.page,
                        t,
                        filterModalData: sideModalData,
                      })
                    }
                  }}
                />
              )}
            </WppGrid>
          </WppGrid>
        </WppGrid>
      </WppGrid>
    </>
  )
}

export default ChangeLog
