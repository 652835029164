import { View } from '@react-pdf/renderer'
import _ from 'lodash'
import React, { Fragment } from 'react'

import FieldBuilder from 'app/features/export/components/pdfViewer/field/fieldBuilder'
import styles from 'app/features/export/components/pdfViewer/field/fieldChildren/styles'
import PDFHelper from 'app/features/export/services/PdfService'
import CommonService from 'services/CommonService'
import FormFieldService from 'services/formField/FormFieldService'
import FormFieldValidationService from 'services/formField/FormFieldValidationService'
import { FIELD_TYPES } from 'types/field/enum'
import IField from 'types/field/IField'

interface IFieldChildrenProps {
  field: IField
  optionValue: string
  homeUrl: string
  projectId: string
}

const formFieldService = new FormFieldService()
const commonService = new CommonService()

/**
 * Create children field
 * Show field children if any
 * @param {object} props
 * @param {IField} props.field
 * @param {any} props.optionValue
 * @param {string} props.homeUrl
 * @param {string} props.projectId
 */
const FieldChildren: React.FC<IFieldChildrenProps> = ({
  field,
  optionValue,
  homeUrl,
  projectId,
}: IFieldChildrenProps): React.ReactElement => {
  const { value } = field
  const children: IField[] = formFieldService.getChildren(field, value)

  return (
    <View style={_.isEqual(field.type, FIELD_TYPES.RADIO) ? styles.singleValChildren : styles.multiValChildren}>
      {children.map((childField: IField) => {
        if (commonService.findAnyArrayValueInTargetArray(childField.visibleOnValue, [_.toString(optionValue)])) {
          return FormFieldValidationService.isEmptyField(childField) ? (
            <Fragment key={childField.id} />
          ) : (
            <FieldBuilder
              key={childField.id}
              homeUrl={homeUrl}
              projectId={projectId}
              field={{ ...childField, value: PDFHelper.getFieldValue(childField) }}
            />
          )
        }
        return <Fragment key={childField.id} />
      })}
    </View>
  )
}

export default FieldChildren
