import _ from 'lodash'
import React from 'react'

import FieldBuilder from 'app/features/export/components/pdfViewer/field/fieldBuilder'
import PDFHelper from 'app/features/export/services/PdfService'
import IField from 'types/field/IField'
import IRepeatableField from 'types/field/repeatable/IRepeatableField'

interface IFieldProps {
  field: IField
  homeUrl: string
  projectId: string
}

/**
 * PDF field component
 * @param {object} props
 * @param {IField} props.field
 * @param {string} props.homeUrl
 * @param {string} props.projectId
 */
const Field: React.FC<IFieldProps> = ({ field, homeUrl, projectId }: IFieldProps): React.ReactElement => {
  if (_.gt(field.maxRepeatableAmount, 0)) {
    return (
      <>
        {field.repeatableFields.map((rField: IRepeatableField, index: number) => {
          const updatedField: IField = { ...field, value: rField.value }
          const fieldValue = PDFHelper.getFieldValue(updatedField)
          const fieldLabel = !field.fieldConfig.text ? '' : `${field.fieldConfig.text} ${index + 1}`

          return (
            <FieldBuilder
              key={rField.id}
              homeUrl={homeUrl}
              projectId={projectId}
              field={{
                ...field,
                value: fieldValue,
                fieldConfig: {
                  ...field.fieldConfig,
                  text: fieldLabel,
                },
              }}
            />
          )
        })}
      </>
    )
  }
  return (
    <FieldBuilder homeUrl={homeUrl} projectId={projectId} field={{ ...field, value: PDFHelper.getFieldValue(field) }} />
  )
}

export default Field
