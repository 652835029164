import { useOs } from '@wpp-open/react'
import _ from 'lodash'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { WppButton } from 'app/components/common'
import ApproveModal from 'app/features/review/components/approve/approveModal'
import RejectModal from 'app/features/review/components/reject/rejectModal'
import ReviewHelper from 'app/features/review/services/ReviewService'
import { AppDispatch, RootState } from 'store'
import IAppContextState from 'store/interfaces/IAppContextState'
import IProjectBriefState from 'store/interfaces/IProjectBriefState'
import IQuestionnaireState from 'store/interfaces/IQuestionnaireState'
import IReviewState from 'store/interfaces/IReviewState'
import { setApproveModal, setRejectModal } from 'store/reducers/reviewSlice'
import { QUESTIONNAIRE_STATUS, REVIEW_RESPONSE_TYPES, REVIEW_STATUS } from 'types/review/enum'

/**
 * ReviewAction component
 * Shows Approve/Reject action button to reviewer
 */
const ReviewAction: React.FC = (): React.ReactElement => {
  const appContext = useSelector<RootState, IAppContextState>((state: RootState) => state.appContext)
  const projectBriefState = useSelector<RootState, IProjectBriefState>((state: RootState) => state.projectBriefState)
  const reviewState = useSelector<RootState, IReviewState>((state: RootState) => state.reviewState)
  const questionnaireState = useSelector<RootState, IQuestionnaireState>((state: RootState) => state.questionnaireState)
  const { t } = useTranslation()
  const { app } = questionnaireState
  const { questionnaire } = projectBriefState
  const { osApi } = useOs()
  const dispatch = useDispatch<AppDispatch>()

  /**
   * Handles review action
   * @param {REVIEW_RESPONSE_TYPES} responseType
   * @param {string} responseReason
   * @returns {void}
   */
  const handleReviewerAction = (responseType: REVIEW_RESPONSE_TYPES, responseReason: string): void => {
    ReviewHelper.handleReviewerAction({
      accessToken: osApi.getAccessToken(),
      dispatch,
      projectQuestionnaireId: _.toString(projectBriefState.questionnaire.projectQuestionnaireId),
      questionnaireState,
      responseType,
      responseReason,
      tenantId: appContext.tenantId,
      appName: appContext.appName,
      appInstanceId: appContext.appInstanceId,
      t,
      appContext,
    })
  }

  if (!(questionnaire.approval?.isReviewer && app!.isProjectMember && !_.isNull(reviewState.reviewerInfo))) return <></>

  if (
    !_.isEqual(reviewState.reviewerInfo.status, REVIEW_STATUS.PENDING) ||
    !_.isEqual(questionnaire.approval?.status, QUESTIONNAIRE_STATUS.PENDING)
  ) {
    return <></>
  }

  return (
    <>
      <WppButton
        id="btn-reject-form"
        size="s"
        data-testid="btn-reject-form"
        onClick={() => dispatch(setRejectModal(true))}
        variant="secondary"
      >
        <Trans>app.button.reject_form</Trans>
      </WppButton>
      <WppButton
        size="s"
        id="btn-approve-form"
        data-testid="btn-approve-form"
        onClick={() => dispatch(setApproveModal(true))}
      >
        <Trans>app.button.approve_form</Trans>
      </WppButton>
      <ApproveModal onApprove={() => handleReviewerAction(REVIEW_RESPONSE_TYPES.APPROVAL, '')} />
      <RejectModal onReject={(reason: string) => handleReviewerAction(REVIEW_RESPONSE_TYPES.REJECTION, reason)} />
    </>
  )
}

export default ReviewAction
