import { useOs } from '@wpp-open/react'
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import FormFieldUser from 'app/components/categorySection/formField/FormFieldUser'
import {
  WppActionButton,
  WppButton,
  WppFilterButton,
  WppListItem,
  WppSelect,
  WppSideModal,
  WppToggle,
  WppTypography,
} from 'app/components/common'
import styles from 'app/features/changeLog/components/filter/SideModalFilter.module.scss'
import { FilterService } from 'app/features/changeLog/services'
import { AppDispatch, RootState } from 'store'
import IChangeLogState from 'store/interfaces/IChangeLogState'
import IQuestionnaireState from 'store/interfaces/IQuestionnaireState'
import {
  clearFilter,
  setFilterData,
  setFilterFormFields,
  setOpenFilterModal,
  setSelectedFilterData,
} from 'store/reducers/changeLogSlice'
import ICategory from 'types/category/ICategory'
import IChangeLogFilter from 'types/changeLog/IChangeLogFilter'
import IChangeLogSelectedFilter from 'types/changeLog/IChangeLogSelectedFilter'
import { SelectChangeEventDetail, WppSelectCustomEvent } from 'types/common/utils'
import { FIELD_TYPES } from 'types/field/enum'
import IField from 'types/field/IField'
import IFieldChangeEvent from 'types/field/IFieldChangeEvent'
import { ORDER_BY } from 'types/table/enum'

interface ISideModalFilterProps {
  handleFilterChange: (filter: IChangeLogFilter, orderBy: ORDER_BY) => void
}

/**
 * Change Log Side Filter modal Component
 * Side modal filter component for change log table
 */
const SideModalFilter: React.FC<ISideModalFilterProps> = ({
  handleFilterChange,
}: ISideModalFilterProps): React.ReactElement => {
  const dispatch = useDispatch<AppDispatch>()
  const formFieldRef = useRef<HTMLWppSelectElement>(null)
  const { osApi } = useOs()
  const { t } = useTranslation()
  const { openFilterModal, sideModalData, filterSelectedData, tableData, filterCount } = useSelector<
    RootState,
    IChangeLogState
  >((state: RootState) => state.changeLogState)

  const questionnaireState = useSelector<RootState, IQuestionnaireState>((state: RootState) => state.questionnaireState)

  const [formData, setFormData] = useState<IChangeLogSelectedFilter>(filterSelectedData.sideModal)

  const setFilterModal = (open: boolean) => {
    dispatch(setOpenFilterModal(open))
  }

  const updateDropdowns = (categories: ICategory[], fieldType: FIELD_TYPES | null = null) => {
    const formFields: Array<IField> = FilterService.getFieldsByCategories(categories, fieldType)
    dispatch(setFilterFormFields(formFields))
    setFormData((prevState: IChangeLogSelectedFilter) => ({
      ...prevState,
      selectedCategories: categories,
      selectedFormFields: formFields,
    }))
  }

  useEffect(() => {
    if (questionnaireState.app) {
      dispatch(
        setFilterData({
          app: questionnaireState.app,
          viewOnlyFiles: formData.viewOnlyFiles,
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionnaireState.app])

  useEffect(() => {
    if (openFilterModal) {
      const formFields: Array<IField> = FilterService.getFieldsByCategories(
        filterSelectedData.sideModal.selectedCategories,
        filterSelectedData.sideModal.viewOnlyFiles ? FIELD_TYPES.FILE_UPLOAD : null,
      )
      dispatch(setFilterFormFields(formFields))
      setFormData(filterSelectedData.sideModal)
      formFieldRef?.current?.updateOptions()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSelectedData.sideModal, openFilterModal])

  const userFieldConfig: IField = {
    ...formData.selectedUserField,
    fieldConfig: {
      ...formData.selectedUserField.fieldConfig,
      accessToken: osApi.getAccessToken(),
      placeholder: t('change_log.filter.placeholder.user_field'),
    },
  }

  const handleClearAll = (): void => {
    dispatch(clearFilter())
  }

  return (
    <>
      <WppFilterButton onClick={() => setFilterModal(true)} counter={filterCount}>
        <Trans>app.button.filter</Trans>
      </WppFilterButton>
      {createPortal(
        <WppSideModal
          data-testid="chg-log-side-filter-modal"
          size="s"
          open={openFilterModal}
          onWppSideModalClose={event => {
            if (_.isEqual(event.detail.reason, 'escapePress')) return
            setFilterModal(false)
          }}
          disableOutsideClick
        >
          <h3 slot="header">
            <Trans>change_log.filter.header_title</Trans>
          </h3>
          <div slot="body" className={styles.sideModalBody}>
            <div className={styles.fieldContainer}>
              <WppToggle
                labelConfig={{
                  text: t('change_log.filter.label.view_uploaded_files'),
                }}
                checked={formData.viewOnlyFiles}
                onWppChange={({ detail: { checked } }) => {
                  updateDropdowns(formData.selectedCategories, checked ? FIELD_TYPES.FILE_UPLOAD : null)
                  formFieldRef?.current?.updateOptions()
                  setFormData((prevState: IChangeLogSelectedFilter) => ({
                    ...prevState,
                    viewOnlyFiles: checked,
                  }))
                }}
                className={styles.checkbox}
                required
              />
            </div>
            <div className={styles.fieldContainer}>
              <WppTypography type="s-strong">
                <Trans>change_log.filter.label.categories</Trans>
              </WppTypography>
              <WppSelect
                data-testid="category-change"
                onWppChange={(event: WppSelectCustomEvent<SelectChangeEventDetail>) => {
                  updateDropdowns(event.detail.value, formData.viewOnlyFiles ? FIELD_TYPES.FILE_UPLOAD : null)
                  formFieldRef?.current?.updateOptions()
                }}
                placeholder={t('change_log.filter.placeholder.categories')}
                value={formData.selectedCategories}
                withSearch
                withFolder
                type="multiple"
              >
                {sideModalData.categories.map((category: ICategory) => (
                  <WppListItem value={category} key={category.id}>
                    <p slot="label">{category.name}</p>
                  </WppListItem>
                ))}
              </WppSelect>
            </div>
            <div className={styles.fieldContainer}>
              <WppTypography type="s-strong">
                <Trans>change_log.filter.label.form_fields</Trans>
              </WppTypography>
              <WppSelect
                ref={formFieldRef}
                data-testid="form-field-change"
                onWppChange={(event: WppSelectCustomEvent<SelectChangeEventDetail>) => {
                  setFormData((prevState: IChangeLogSelectedFilter) => ({
                    ...prevState,
                    selectedFormFields: event.detail.value,
                  }))
                }}
                disabled={_.isEmpty(formData.selectedCategories)}
                placeholder={t('change_log.filter.placeholder.form_fields')}
                value={formData.selectedFormFields}
                withSearch
                withFolder
                type="multiple"
              >
                {sideModalData.formFields.map((field: IField) => (
                  <WppListItem value={field} key={field.id}>
                    <p slot="label">{field.fieldConfig.text}</p>
                  </WppListItem>
                ))}
              </WppSelect>
            </div>
            <div className={styles.fieldContainer}>
              <WppTypography type="s-strong">
                <Trans>change_log.filter.label.user_names</Trans>
              </WppTypography>
              <FormFieldUser
                data-testid="user-field-change"
                field={userFieldConfig}
                handleChange={(event: IFieldChangeEvent) => {
                  setFormData((prevState: IChangeLogSelectedFilter) => ({
                    ...prevState,
                    selectedUserField: {
                      ...prevState.selectedUserField,
                      value: event.value,
                    },
                  }))
                }}
              />
            </div>
          </div>
          <div slot="actions" className={styles.actions}>
            <WppActionButton onClick={handleClearAll} data-testid="btn-chg-log-filter-clearAll" variant="secondary">
              <Trans>app.button.clearAll</Trans>
            </WppActionButton>
            <div className={styles.btnActionContainer}>
              <WppButton
                data-testid="btn-chg-log-filter-cancel"
                variant="secondary"
                onClick={() => setFilterModal(false)}
              >
                <Trans>app.button.cancel</Trans>
              </WppButton>
              <WppButton
                data-testid="btn-chg-log-filter-apply"
                onClick={() => {
                  dispatch(setSelectedFilterData(formData))
                  handleFilterChange(
                    {
                      ...filterSelectedData,
                      sideModal: formData,
                    },
                    tableData.orderBy,
                  )
                }}
                disabled={_.isEmpty(formData.selectedCategories) || _.isEmpty(formData.selectedFormFields)}
              >
                <Trans>app.button.apply</Trans>
              </WppButton>
            </div>
          </div>
        </WppSideModal>,
        document.body,
      )}
    </>
  )
}

export default SideModalFilter
